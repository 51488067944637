import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clock',
  templateUrl: './clock.component.html',
  styleUrls: ['./clock.component.less']
})
export class ClockComponent implements OnInit {

  date: Date;
  constructor() { }

  ngOnInit(): void {
    this.date = new Date();
    setInterval(() => this.setDate(), 1000);
  }

  setDate(): void {
    this.date = new Date();
  }

}
