import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ClockComponent } from '../component/clock/clock.component';



@NgModule({
  declarations: [
    ClockComponent
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [
      FormsModule,
      ClockComponent
  ]
})
export class SharedModule { }
