import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileSelectDirective, FileUploadModule } from 'ng2-file-upload';
import { TranscribeComponent } from './transcribe/transcribe.component';
import { AppModule } from '../app.module';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations : [
    TranscribeComponent,
    // FileSelectDirective
  ],
  exports : [
    TranscribeComponent
  ],
    imports: [
        CommonModule,
        FileUploadModule,
        SharedModule
    ]
})
export class TranscribeModule {
}
